import { CONTENT_LIST } from '../comp-mapping'

const localStorageHelper = {
  setObject: function(key, value) {
    try {
      const serializedValue = JSON.stringify(value)
      localStorage.setItem(key, serializedValue)
    } catch (error) {
      console.error('Error storing object in localStorage:', error)
    }
  },
  getObject: function(key) {
    try {
      const serializedValue = localStorage.getItem(key)
      if (serializedValue === null) {
        return null
      }
      return JSON.parse(serializedValue)
    } catch (error) {
      return null
    }
  }
}

const buildBiSrcParams = (product, pageReference) => {
  const srcIdentifierList = [`tn=${product.channelName}`, `ci=${product.contentCarrierId}`,
    `ct=${product?.carrierTypeName ?? '精选'}`,
    `cs=${product?.carrierSubTypeName ?? '全集'}`, `si=205`, `ps=${product?.productIndex + 1}`,
    `jc=${product?.carrierSubTypeName ?? '全集'}_${product?.contentCarrierId}`]
  if (pageReference) srcIdentifierList.push(pageReference)
  return srcIdentifierList.join('`')
}

const getSrcIdentifier = ({ product, productIndex, tabName, sceneId = 205, pageReference, hasVideo, playVideoStatus }) => {
  const srcIdentifierList = [`tn=${tabName}`, `ci=${product.contentCarrierId}`,
    `ct=${product?.carrierTypeName ?? '精选'}`,
    `cs=${product?.carrierSubTypeName ?? '全集'}`, `si=${sceneId}`, `ps=${productIndex + 1}`,
    `jc=${product?.carrierSubTypeName ?? '全集'}_${product?.contentCarrierId}`,
    `cate_id=${product?.cat_id ?? '-'}`
  ]
  if (pageReference) srcIdentifierList.push(pageReference)
  if (playVideoStatus) srcIdentifierList.push(`vp=${product?.carrierSubTypeName === '全集' && hasVideo ? `${hasVideo ? '1' : '0'}_${playVideoStatus}` : '-'}`)
  return srcIdentifierList.join('`')
}

/**
 * 内容体样式类型枚举
 *
 * 不同类型对应的前段组件见 public/src/pages/config_index/components/information-flow-ccc/comp-mapping.js
 */
const cardStyleTypeEnum = {
  // 文案+图片，对应组件 OneBigImgSlider
  CARD_ITEM_WITH_TITLE: 'CARD_ITEM_WITH_TITLE',

  // 轮播图，对应组件 InformationFlowOperation
  CARD_IMAGE_CAROUSEL: 'CARD_IMAGE_CAROUSEL',
}

const getCccxItemConfigByType = (tabList, type) => {
  const informationFlowContentStyleConfig = tabList?.[0]?.pageContentResponse?.content?.[0] || {}
  const cccxConfigItems = informationFlowContentStyleConfig?.props?.items || []
  const getConfigByType = (type) => cccxConfigItems.find(item => item.cardStyleType === type)
  return getConfigByType(type)
}

const getCccxCardConfigByCarrierTypes = (cccxConfigItem, carrierTypes) => {
  const fallback = {}
  if (cccxConfigItem) {
    const currentCccxConfig = cccxConfigItem.contentCarriers.find(cc => cc?.contentCarrierType?.some(type => type.join(',') === carrierTypes.join(',')))
    return currentCccxConfig || fallback
  }
  return fallback
}

/**
 * 返回已曝光的商品的最大索引
 * @param {*} Params.products 商品总列表
 * @param {*} Params.exposedGoodsList 通过商卡曝光事件收集的全集商品列表
 * @param {*} Params.exposedContentList 通过商卡曝光事件收集的内推体列表
 * @returns 
 */

const getExposedMaxIndex = (products, exposedGoodsList, exposedContentList) => {
  let exposedMaxIndex = 0 // 最大已曝光商品的位置
  const total = products.length - 1
  for(let i = total; i >= 0; i--) {
    const item = products[i]
    if (Number(item?.ext?.carrierSubType) === 27 || Number(item?.ext?.carrierType) === 84) {
      if (exposedGoodsList.includes(item.goods_id)) {
        exposedMaxIndex = i
        break
      }
    } else if (CONTENT_LIST.includes(Number(item?.ext?.carrierSubType)) || Number(item?.ext?.carrierType) === 19) {
      if (exposedContentList.includes(item.contentCarrierId)) {
        exposedMaxIndex = i
        break
      }
    }
  }
  return exposedMaxIndex
}

// 商卡兜底配置
const defaultCardConfig = {
  hideSoldOut: false,
  hideFlashSaleIcon: false,
  showSeriesBrand: true,
  useOwnClickExposeAnalysis: true,
  disableMainimgJump: true,
  showSheinClubDiscountValueAfterPrice: true,
  showSheinClubDiscountValuePriceRight: true, // 展示标签在价格后面，且在最右边，需要同时设置这两个属性
  hideDiscountBadge: true,
  hideRetailPrice: true,
  showBeltLabel: true, // 普通腰带
  showFollowLabel: false, // 跟价款腰带
  showDiscountLabelAfterPrice: true,
  hideIconBeforePrice: false, // 价格前面的icon 如⚡️
  showSellingPoint: true,
  showLocalSeller: true,
  configReady: true,
  useOwnClickAddBagAnalysis: true,
  showNewDiscountLabel: true, // abt 控制强化角标
  showGreySellingPoint: true, // abt控制
  showCamelPrice: true,
  autoCropImage: true,
  showEstimatedPrice: true, // 到手价
  showEstimatedPriceReachThreshold: true,
  showUserBehaviorLabel: true, // 用户行为标签
  showUserPreferencesLabel: true, // 用户偏好标签
  showPriceDiscountDesc: true, // 历史低价、保留款跟价款标签
  style: {
    pricesNewline: false,
    salePriceFontSize: '12px',
    lineThroughPriceFontSize: '12px',
  },
  showDiscountLabelBeforeGoodsName: false, // 折扣标签优先级低于标题前的其他标签
  showDiscountLabelBeforeGoodsNameHighPriority: true, // 折扣标签优先级高于标题前的其他标签
  hideFlashSaleCountDown: false, // 闪购腰带不展示倒计时展示可省金额
  showRankingList: true,
  showStarComment: true, // 场景ABT控制
  showDecisionAttr: true, // 场景ABT控制
  showEstimatedPriceOnSale: true, // 到手价abt
  showQuickShip: true,
  showBadge: true,
  showBestDealLabel: true,
  showMultiColor: true, // 多色块
  rankingLabelClickable: true,
  showHorseRaceLabel: true,
  addRankingLabelToSwiper: false,
  showPropertyUpsell: false,
  showNewuseronlyPriceLabel: true, // 展示新客到手价说明
  showDeliveryWords: true, // 趋势标签
  showSheinClubDiscountValue: false, // 不展示大号付费会员折扣标签
  showSheinClubLabelInServicesLabel: true, // 付费会员标签展示在服务标签里面
  showNewFlashNewLabel: true, // 展示新的新型闪购文字标签
  showTitle: true,
  showTitleTwoLine: false,
  showAddBagBtn: false,
  showSheinClubPriceOnSale: false, // 是付费会员，就将价格展示为付费会员价格
  showSheinClubNewLabel: false, // 展示新的付费会员文字标签
  standardPrice: false,
}

// 原子兜底(从这里来的services/product_atom/configManager/implementations/twoPic.js)
const defaultAtomFields = {
  baseInfo: true,
  userPreferences: true,
  prices: true,
  detailImage: true,
  promotion: true,
  seriesAndBrand: true,
  cccTspBadges: true,
  topPick: false,
  cccFeedback: true,

  // 这 3 个是促销相关的 fields, 可以默认写死为 true, 原子服务内部会根据实际情况判断是否真正请求上游的促销接口
  sheinClubPromotionInfo: true,
  newFlashPromotion: true,
  vipDiscountPrices: true,

  // realTimeTspLabels: (realTimeTspLabels.machine_label.length > 0 || realTimeTspLabels.label_cloud.length > 0) ? realTimeTspLabels : undefined,
  
  userBehaviorLabel: true,  // 用户行为标签
  userCommentKeywordsLabel: true, // 用户评论关键词标签
  beltLabel: true,
  rankingList: true,
  flashZonePromotion: true,
  horseRaceLabel: true,
  deliveryInfo: true,
  cornerPropertyUpsell: true, // 属性销量标签

  relatedProducts: true,
  quickShip: true,
  plusSize: true,
  stock: true,
  mallCodeList: true, // 拿到mall_code_list进行过滤
}
export {
  localStorageHelper,
  buildBiSrcParams,
  getSrcIdentifier,
  cardStyleTypeEnum,
  getCccxItemConfigByType,
  getCccxCardConfigByCarrierTypes,
  defaultCardConfig,
  defaultAtomFields,
  getExposedMaxIndex,
}
