import { defineAsyncComponent } from 'vue'

export const commonComponents = {
  OneBigImgSlider: defineAsyncComponent(() => import('./OneBigImgSlider')), // 一大图轮播样式
  Operation: defineAsyncComponent(() => import('./Operation')), // 运营位样式
  // OneBigImgAndThreeSmallImg: defineAsyncComponent(() => import('./OneBigImgAndThreeSmallImg')), // 一大图三小图样式, 一大图三小图样式暂时没有使用，代码先保留
}
const componentMap = {
  12: 'OneBigImgSlider',
  13: 'OneBigImgSlider',
  62: 'TrendContent',
  19: 'Operation',
  63: 'FashionStore',
  84: 'BehaviorProductSet',
  88: 'ChoiceStore',
}

//新增内容体需要在这里补充
export const CONTENT_LIST = [12, 13, 62, 63, 88]

// 端去重白名单
export const DEDUPLICATION_LIST = [20, 21, 22, 23, 24, 25, 36, 37, 38, 39, 40, 41, 42, 43, 44, 48, 49, 58, 60, 85, 86, 87]

export const getComponentsName = (ext)=> {
  if(ext.carrierType == '19' || ext.carrierType == '84'){
    return componentMap[ext.carrierType]
  }
  // 12:新品 13:折扣 62: 趋势
  if(CONTENT_LIST.includes(Number(ext.carrierSubType))){
    return componentMap[ext.carrierSubType]
  }
}
