const homeRefreshIdsMap = {}
if (typeof window !== 'undefined') {
  window.homeRefreshIdsMap = homeRefreshIdsMap
}

const layoutAnalysis = {
  // 重置上报参数
  refreshAnalysisParams({ params = {} } = {}) {
    if (typeof window != 'undefined') {
      // window.PageGroupOverview = 'home'
      // window.PageGroup = 'home'
      // 页面信息

      // 浏览会话唯一 ID（页面刷新才会变）
      const currentTabHomeRefreshId = homeRefreshIdsMap[params.tab_id]
      if (currentTabHomeRefreshId) {
        params.home_refresh_id = currentTabHomeRefreshId
      } else {
        const home_refresh_id = new Date().getTime()
        window.homeRefreshIdsMap[params.tab_id] = home_refresh_id
        params.home_refresh_id = home_refresh_id
      }

      window.SaPageInfo = {
        page_id: 1,
        page_name: 'page_home',
        page_param: Object.assign({}, params),
        start_time: new Date().getTime(),
      }
      // 重新上报pv时需清空上报过的曝光组件
      sessionStorage?.removeItem('cccCompExposeMonitor')
      // this.exposeGoodIdList = []
    }
  },
  removeHomeRefreshId() {
    if (
      typeof window != 'undefined'
      && window.SaPageInfo?.page_param
    ) {
      window.SaPageInfo.page_param.home_refresh_id = (void 0)
    }
  }
}
export default layoutAnalysis
