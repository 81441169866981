import exposeClass from 'public/src/services/expose/index.js'
import { Scroll, windowLinstnerScrollEventInstance as scrollInstance } from 'public/src/services/expose/index.js'

const guid = () => Number(Math.random().toString().substr(3, 3) + Date.now()).toString(36)
/**
 * 用以更新元素绑定的数据
 * vue指令不不能支持动态数据，需要在不同的钩子之间进行通信
 */
const bindInfo = {
  data: new Map(),
  get(id) {
    return this.data.get(id)
  },
  set(id, value) {
    if (!id) return false
    if (value) this.data.set(id, value)
    return true
  },
  delete(id) {
    return this.data.delete(id)
  }
}

const infiniteLoad = {
  /**
   * @param {*} el
   * @param {*} binding
   *    @param {Function} handle 无限滚动触发的函数
   *    @param {Number} distance 无限滚动触发的伐值，纯数字，最终结果是 px
   *    @param {Boolean} isScroller 是否当前绑定的元素就是滚动元素
   * @returns
   */
  mounted(el, binding) {
    if (typeof window === 'undefined') return
    if (!binding.value.handle || typeof binding.value.handle !== 'function') return
    
    const div = el.querySelector('.infinite-ob-element') || document.createElement('div')
    div.classList.add('infinite-ob-element')
    div.style.width = '100%'
    div.style.height = '1px'
    div.style.marginTop = '-1px'
    div.style.overflow = 'hidden'
    div.style.visibility = 'hidden'
    el.appendChild(div)
    
    const distance = parseInt(binding.value.distance || 0) || 0
    // 距离适口distance px的时候触发loadmore
    const ob = new exposeClass({
      mask: [0, 0, `-${distance}px`],
      delay: 100
    })
    
    const code = guid()
    el.setAttribute('ifn-id', `ifn_${code}`)
    let canIScrollReset = false
    const infiniteOb = function() {
      ob.remove(code)
      ob.observe({
        elements: {
          code,
          target: div,
        }
      }, async () => {
        const noData = el.getAttribute('infinite-load-nodata')
        if (noData == 'false') {
          canIScrollReset = false
          await binding.value.handle()
          // 1s后尝试重制曝光（适用于发起请求之后，仍在适口之内可进行继续无限滚动
          setTimeout(() => {
            canIScrollReset = true
            ob.remove(code)
            infiniteOb()
          }, 1000)
        } else {
          canIScrollReset = true
        }
      })
    }
    
    // 观察曝光
    infiniteOb()

    const scrollBehaviorFn = () => {
      if (!canIScrollReset) return
      const noData = el.getAttribute('infinite-load-nodata')
      if (noData == 'false') infiniteOb()
    }
    
    let unBindScroll = null
    // 当前元素是否就是滚动元素
    if (binding.value.isScroller) {
      const elScroller = new Scroll(undefined, {
        scrollContainer: el
      })
      const obsId = elScroller.listen(scrollBehaviorFn, { delay: 1000 })
      // el.addEventListener('scroll', scrollBehaviorFn)
      unBindScroll = () => {
        elScroller.unListen(obsId)
        elScroller.disconnect()
      }
    } else {
      // 否则默认通过window滚动来触发
      const obsId = scrollInstance.listen(scrollBehaviorFn, { delay: 1000 })
      unBindScroll = () => {
        scrollInstance.unListen(obsId)
      }
    }

    // 存当前元素在销毁阶段需要执行的函数
    bindInfo.set(`ifn_${code}`, () => {
      // 移除滚动
      unBindScroll()
      // 移除曝光监听
      ob.destroy()
    })
  },
  unmounted(el) {
    if (typeof window === 'undefined') return
    // 1. 移除观察的元素
    const div = el.querySelector('.infinite-ob-element')
    if (div) el.removeChild(div)
    const ifnId = el.getAttribute('ifn-id')
    // 2. 元素相关订阅信息
    bindInfo.get(ifnId)?.()
    // 3. 删除元素订阅信息
    bindInfo.delete(ifnId)
  },
}

export { infiniteLoad }
