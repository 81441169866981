<template>
  <AppBff
    ref="newCccReference"
    :class="{ 'not-fsp-element': ignoreFSP }"
    :content="banners"
    :context="cccAppContext"
    :scene-data="channelSceneCccData"
    :channel-id="channelId"
    :active-channel-id="activeChannelId"
    :hooks="hooks"
  />
</template>

<script>
import { defineComponent } from 'vue'
import { mapState, mapGetters } from 'vuex'
import AppBff from 'public/src/pages/components/ccc/home-bff-components/index.vue'
export default defineComponent({
  name: 'AppBffContainer',
  components: {
    AppBff,
  },
  props: {
    appContent: {
      type: Object,
      default() {
        return {}
      }
    },
    hooks: {
      type: Object,
      default() {
        return {}
      }
    },
    activeChannelId: {
      type: Number,
      default: 0
    },
    traceId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      ignoreFSP: false,
    }
  },
  computed: {
    ...mapState('config_index', ['context']),
    ...mapGetters('config_index', ['topTabList']),
    banners() {
      return this.appContent.content || []
    },
    channelId() {
      return this.appContent.channelId
    },
    curTab() {
      return this.topTabList?.length ? this.topTabList.find(i => i.channelId === this.channelId) : null
    },
    channelSceneCccData() {
      let isFirstChannel = false
      let channelInfo = {}
      const result = []
      if (this.appContent?.channelAbt?.posKey) result.push(this.appContent.channelAbt.posKey.slice(2))

      if (this.topTabList?.length) {
        const firstChannel = this.topTabList[0] || {}
        isFirstChannel = firstChannel.channelId === this.channelId
      }

      if(this.channelId && this.topTabList?.length) {
        channelInfo = this.topTabList?.find(tab => tab.channelId === this.channelId) ?? {}
      }
      const traceId = this.traceId || this.context?._traceId || ''
      return {
        pageType: this.appContent?.pageType || '',
        pageFrom: 'homePage',
        isFirstChannel,         // 是否是第一个频道
        channelInfo, // 当前频道信息
        poskey: result.join(','),
        sceneName: this.curTab?.channelName,
        tabName: this.curTab?.channelName,
        id: this.appContent?.id || '',
        traceId,
        extendData: typeof this.appContent?.extension == 'string' ? JSON.parse(this.appContent?.extension) : this.appContent?.extension // 页面全局配置
      }
    },
    cccAppContext() {
      const { SiteUID, lang, language, serverTime, WEB_CLIENT, GB_cssRight, PUBLIC_CDN, IMG_LINK, LAZY_IMG_PIXEL, LAZY_IMG, LAZY_IMG_SQUARE, LAZY_IMG_SOLID_COLOR, langPath, clientRender, RESOURCE_SDK } = this.context
      return {
        extension: typeof this.appContent.extension == 'object' ? this.appContent.extension : JSON.parse(this.appContent.extension || '{}'),
        channelId: this.appContent.channelId,
        deliveryId: this.appContent.deliveryId,
        abtInfoObj: this.appContent?.homePageExtra?.webDynamicAbt,
        curChannelInfo: this.curTab,
        clientRender,
        forceRenderNumber: 8,
        WEB_CLIENT,
        SiteUID,
        lang,
        serverTime,
        GB_cssRight,
        langPath,
        PUBLIC_CDN,
        IMG_LINK,
        LAZY_IMG_PIXEL,
        LAZY_IMG,
        LAZY_IMG_SQUARE,
        LAZY_IMG_SOLID_COLOR,
        RESOURCE_SDK,
        // TODO: 优化语言，只指出需要的语言，需要考虑 tab 切换的时候
        language
        // : {
        //   coupon: language.coupon,
        //   flashSale: language.flashSale,
        //   productList: language.productList,
        //   ranking: language.ranking,
        //   dailyNew: language.dailyNew,
        //   countdown: language.countdown,
        //   recomendFlow: language.recomendFlow,
        //   freeshipping: language.freeshipping,
        //   welfare: language.welfare,
        //   couponItem: language.couponItem,
        //   itemLanguage: language.itemLanguage,
        //   slideBanner: language.slideBanner,
        //   couponDynamic: language.couponDynamic,
        //   superSales: language.superSales,
        //   quarterProduct: language.quarterProduct,
        //   trendStoreRecommend: language.trendStoreRecommend,
        //   trendEntry: language.trendEntry,
        //   purchaseCouponBanner: language.purchaseCouponBanner,
        // },
      }
    },
  },
  mounted() {
    let fspClassWatch = this.$watch('$route', (to, from) => {
      if (!this.ignoreFSP && (to.name !== 'config_index' || (to.name === from.name && this.channelId !== this.activeChannelId))) {
        this.ignoreFSP = true
        fspClassWatch()
        fspClassWatch = null
      }
    })
  },
  methods: {
    refreshAnalysis() {
      this.$refs['newCccReference']?.resetExpose?.()
    }
  },
})
</script>
